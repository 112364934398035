.imgLogo{
    width: 40px;
    margin-top: 3px;
    margin-bottom: 3px;
    margin-right: 3px;
    font-size: 30px;
}

.textLink{
    text-decoration: none;
    font-size: 25px;
    color : #F4EBD0;
}

.textContentContactMe{
  animation-duration: 1s;
}

.modal-contentContactMe {
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
    animation-name: appearContactMe;
    animation-duration: 0.5s;
    transform: translateY(42px, 18px);
    width: 300px;
    height: 150px;
    background-color: #cdd7ea;
    padding-top: 5px;
    border: solid;
    border-width: 2px;
    padding-bottom: 20px;
    border-radius: 8px;
    position: fixed;
    top:-135%; right:-100%; left:-100%; bottom:-100%;
    margin:auto;
    z-index:0;
    box-sizing:content-box;
  }

  @keyframes appearContactMe {
    0%{
      opacity: 0;
      
      z-index:0;
    }
  }

.textLink  :hover ,
.textLink :focus{
    cursor: pointer;
    color : white;
}


  @keyframes disapearContactMe {
    80%{
      opacity: 0;
      visibility: hidden;
    }

    100%{
      opacity: 0;
      visibility: hidden;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  @keyframes showTextContactMe {
    0% {
      visibility: hidden;
        opacity: 0;
    }
    50%{
      visibility: hidden;
        opacity: 0;
    }
    100% {
      visibility: visible;
        opacity: 1;
    }
}

  .skillBoxContactMe{
    background-color: #becce4;
    border-radius:5px 5px 5px 5px;
}

  @media screen and (max-width: 600px) {
  
    .modal-contentContactMe{
      top: -120%; right:-100%; left:-100%; bottom:-100%;
      width: 80%;
      height: fit-content;
    }

    .imgLogo{
      width: 11vw;
    }
  }

  .headerCM{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    padding-bottom: 10px;
    background-color: #cdd7ea;
  }




