@font-face {
  font-family: dxfont;
  src: url(fonts/Questrial-Regular.ttf);
}

body, html {
  -webkit-text-size-adjust: 100%;

}
.fontFamily {
  font-family: dxfont;
  color :#0B1F42;
}

.fontColor {
  color: #0B1F42 ;
}


.logo {
 justify-content: center;
 align-items: center;
 display: flex;
 color: #dbe3f0;
 font-family: dxfont;
 text-align: center;
 font-size: 130px;
 line-height:80px;
 margin: 0;
 height: auto;

}

.bg {
  background: linear-gradient(109.6deg, rgb(20, 30, 48) 12%, rgb(36, 59, 85) 92%);
  animation: gradient 15s ease infinite;
  width: 100vw;
  height: 100vh;
}

@keyframes gradient {
  0% {
      background-position: 0% 50%;
  }
  50% {
      background-position: 100% 50%;
  }
  100% {
      background-position: 0% 50%;
  }
}

#content {
  top: 25%;
  position: relative;
  
}

.menuMainPage{
  margin-top: 25px;
  justify-content: space-between;
  align-items: center;
  display: flex;
  border-radius: 5px 5px 5px 5px;
  width: 100%;
  outline-color:#dbe3f0 ;
  border-color: white;
}

.btnView{
font-size: 25px;
border: hidden;
font-family: dxfont;
border-radius:12px 12px 12px 12px;
display: inline-flex;
justify-content: center;
align-items: center;
background-color: transparent;
color: white;
}

.btnView:active, .btnView:hover{
 color:  #6888c0;
}

.prevent-select {
  -webkit-user-select: none; 
  -ms-user-select: none; 
  user-select: none;
}

@media screen and (max-width: 600px) {
  .bg {
   height:100vh;
   overflow: hidden;
  }

  #content {
    width: 100%;
  }

  .logo {
    font-size: 80px;
    margin:6%;
    margin-bottom: 0;
    height: fit-content;
  }

  .menuMainPage{
    border : none;
    display: flex;
    flex-direction: column;
    -ms-flex-align: center;
    background-color: transparent;
    justify-content: center;    
    height: auto;
    outline: none;
    margin-top: 10px;
  }
  .btnView{
    font-size: 25px;
    width: 385px;
    height: 60px;
    position: relative;
    margin-bottom: 10px;
    border-color: navy;
    border-radius: 5px 5px 5px 5px;
    border: solid;
    border-width: 2px;
    border-color:#dbe3f0 ;
   }
 
}

@media screen and (min-width: 601px) and (max-width: 900px)  {
  #content{
    width: max-content;
  }

  .logo {
    font-size: 100px;
   }

   .menuMainPage{
   height: auto;
   margin-top: 20px;
   }

.btnView{
  font-size: 20px;
 }
}

@media screen and (max-width: 450px) {
  .bg {
   height:100vh;
   overflow: hidden;
  }

  .logo {
    font-size: 70px;
   }

   .btnView{
    width: 340px;
   }

}

