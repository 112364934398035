.modal {
    display :flex;
    left: 0;
    top: 0;
    width: 100%; 
    height: 100%; 
    background-color:  transparent; 
  }

  .modal-content {
    padding: 15px;
    padding-left: 25px;
    padding-right: 0px;
    animation-name: appear;
    animation-duration: 0.5s;
    height: max-content;
    max-height: 90%;
    width: 750px;
    background-color:#cdd7ea; 
    border-radius:5px;
    border: solid;
    border-color: #cdd7ea;
    position: fixed;
    top:-100%; right:-100%; left:-100%; bottom:-100%;
    margin:auto;
    box-sizing:content-box;
  }

 
  ::-webkit-scrollbar {
  width: 10px;
  color: wheat;
  background-color: transparent;
  background-clip: content-box;
  
  }
  ::-webkit-scrollbar-thumb {
    background: #6888c0;
  }

.textContent{
  animation-duration:2s;
  overflow: auto;
}

.header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px;
    padding-bottom: 10px;
    width: 94%;
    position: absolute;
    background-color: #cdd7ea;
}

.Hey{
  margin-top :65px;
  padding: 10px;
}

.padding10{
  padding: 10px;
  line-height:5px;
}

.studies{
    display: flex;
}

.img{
    max-width: 40%;
    height: 50px;
    border-radius:5px;
}

.imgCyframe{
  max-width: 40%;
  height: 50px;
  border-radius: 5px;
}

.headerText {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 28px;
    padding-top: 10px;
}

.skillBox{
    background-color: #becce4;
    padding-top: 5px;
}

@keyframes appear {
  0%{
    opacity: 0;
    
    z-index:0;
  }
}

@keyframes disapear {

  50%{
    opacity: 0;
    visibility: hidden;
  }
  100%{
    opacity: 0;
    visibility: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@keyframes showText {
    0% {
      visibility: hidden;
        opacity: 0;
    }
    100% {
      visibility: visible;
        opacity: 1;
    }
}

@keyframes removeText {
    0% {
      visibility: hidden;
    }
    100% {
      visibility: hidden;
    }
}
 
.close {
    color: #26385a;
    width: 50px;
    height: 38px;
    font-size: 28px;
    font-weight: bold;
    background-color: #cdd7ea;
    border-style: solid;
    border-color: #26385a ;
    background: transparent;
    border-width: 2px;
    border-radius:5px 5px 5px 5px; 
    display: flex;
    justify-content: center;
    align-items: center;
}
  
  .close:hover,
  .close:focus {
    color: #6888c0;
    text-decoration: none;
    cursor: pointer;
    border-color: #6888c0;
  }

  @media screen and (min-width: 601px) and (max-width: 900px)  {
    .modal-content {
      width: 75%;
      height: 90%;
      border-width:3px;
  }
  .header{
    width: 92%;
  }


}
  @media screen and (min-width: 901px) and (max-width: 1399px)  {
    .modal-content {
      height: 90%;
      border-width:3px;
  }
  }

  @media screen and (max-width: 600px) {
    .modal-content {
        width: 97%;
        height: 97%;
        border-width:3px;
        box-sizing: border-box;
        max-height: 97%;
        padding: 7px !important;
        padding-right: 0px !important;
        padding-left: 0;
    }
    .img{
      max-width: 40%;
      height: 35px;
      border-radius:5px;
  }
    .imgCyframe{
      max-width: 40%;
      height: 35px;
      border-radius: 5px;
  }
  .header{
    margin-top:0;
    width: 96%;
  }
  .Hey{
   padding: 5px;
  }
  }

